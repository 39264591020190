import React, { useContext } from "react";
import { cn } from "../lib/helpers";
import { GetStarted, SectionWrapper } from "../components/sections";
import { CardGrid } from "../components/card/card-grid";
import { InlineCardQuote } from "../components/inline-card";
import { BackToZone } from "../components/back-to-zone";
import { SiteContext } from "../components/global/site-context";
import {
  IntegrationsTiles,
  TableOfContentsSection,
} from "../components/playbook-page";
import PortableText from "../components/portableText";
import { ImageBuilder } from "../components/global/image-builder";

import * as styles from "../components/portableText.module.css";
import * as globalStyles from "../components/global/global.module.css";
import { typesMap } from "./sections/resources-list";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

export const StoryPage = ({
  title,
  _rawBody,
  company,
  quote,
  businessModel,
  teams,
  useCases,
  integrations,
  suggested,
}) => {
  const site = useContext(SiteContext);

  return (
    <>
      <SectionWrapper
        container="large"
        name="header"
        background="lavenderUltraLight"
      >
        <div className="relative">
          <div
            className={cn(
              "w-1/3 absolute top-1/2 left-1/2 h-1/3 ml-12",
              globalStyles.radialHighlight
            )}
          ></div>
          <BackToZone to="/customers/" text="See all customer stories" />
          <div className="grid md:grid-cols-3/5-2/5 gap-6 xl:gap-12 relative">
            <div>
              {company && (
                <ImageBuilder
                  image={company.mainImage}
                  quality={100}
                  height={100}
                  style={{ maxHeight: "100px" }}
                  alt={company.title}
                />
              )}
              <h1 className="text-4xl md:text-6xl font-semibold mt-2 md:mr-6 xl:mr-24">
                {title}
              </h1>
            </div>
            <div className="mt-6 flex items-center">
              <InlineCardQuote
                node={quote}
                skipLogo={true}
                simpleLayout={true}
              />
            </div>
          </div>
        </div>
        <MetaDetails {...{ businessModel, teams, useCases, integrations }} />
      </SectionWrapper>

      {_rawBody ? (
        _rawBody?.filter((b) => b._type === "anchor").length > 0 ? (
          <SectionWrapper container="large" padding="tight">
            <TableOfContentsSection {...{ _rawBody }} />
          </SectionWrapper>
        ) : (
          <SectionWrapper container="thin">
            <PortableText blocks={_rawBody} />
          </SectionWrapper>
        )
      ) : null}

      <SectionWrapper
        header={site.token("suggestions")}
        container="large"
        background="lavenderUltraLight"
      >
        <CardGrid
          nodes={suggested}
          browseMoreHref="/customers/"
          browseMoreText="See all customer stories"
          hideLabels={false}
          maxColumns={3}
          makeThumbnail={true}
          showSummary={true}
          allowSummary={true}
          panels={true}
          hideType={true}
          attribution={{ action: "suggestion" }}
          cta={typesMap.get("story")?.cta}
        />
      </SectionWrapper>

      <SectionWrapper container="large" background="mint">
        <GetStarted />
      </SectionWrapper>
    </>
  );
};

const MetaDetails = ({ businessModel, teams, useCases, integrations }) =>
  [businessModel, teams, useCases, integrations].find((n) => n.length > 0) ? (
    <ul className="mt-12 p-2 grid sm:grid-cols-2 lg:grid-cols-4 gap-2 bg-dark-5 rounded-lg font-medium">
      <li className="bg-white p-4 lg:p-5 rounded-lg">
        <p className="text-dark-60 mb-2">Business model</p>
        <ul>
          {businessModel.map((n, i) => (
            <li className="mb-2" key={i}>
              <FontAwesomeIcon icon={faCheckCircle} className="mr-2 max-h-8 text-auxiliary text-sm" />
              {n}
            </li>
          ))}
        </ul>
      </li>
      <li className="bg-white p-4 lg:p-5 rounded-lg">
        <p className="text-dark-60 mb-2">Teams</p>
        <ul>
          {teams.map((n, i) => (
            <li className="mb-1" key={i}>
              <FontAwesomeIcon icon={faCheckCircle} className="mr-2 max-h-8 text-auxiliary text-sm" />
              {n}
            </li>
          ))}
        </ul>
      </li>
      <li className="bg-white p-4 lg:p-5 rounded-lg text-sm">
        <p className="text-dark-60 mb-3">Use cases</p>
        <ul>
          {useCases.map((n, i) => (
            <li className="mb-3" key={i}>
              <FontAwesomeIcon icon={faCheckCircle} className="mr-2 max-h-8 text-auxiliary text-sm" />
              {n}
            </li>
          ))}
        </ul>
      </li>
      <li className="bg-white p-4 lg:p-5 pb-2 lg:pb-3 rounded-lg">
        <p className="text-dark-60 mb-2">Key signals</p>
        <div className="flex flex-col">
          <IntegrationsTiles integrations={integrations} />
        </div>
      </li>
    </ul>
  ) : null;
