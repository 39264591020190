import React from "react";
import { graphql } from "gatsby";
import { LayoutContainer } from "../containers/layout";
import PageHeadMeta from "../components/global/page-head-meta";
import { mapEdgesToNodes, toPlainText } from "../lib/helpers";
import { CardContext } from "../components/card/context";
import { StoryPage } from "../components/story-page";

export const query = graphql`
  query StoryTemplateQuery($id: String!) {
    doc: sanityStory(id: { eq: $id }) {
      _type
      id
      _id
      publishedAt
      title
      slug {
        current
      }
      status
      company {
        ...Company
      }
      quote {
        ...Quotable
      }
      mainImage {
        ...SanityImage
      }
      businessModel
      teams
      useCases
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      _rawBody(resolveReferences: { maxDepth: 12 })
      integrations {
        ...Integration
      }
    }
    recentStories: allSanityStory(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: {
        slug: { current: { ne: null } }
        status: { eq: "published" }
        id: { ne: $id }
      }
    ) {
      edges {
        node {
          ...cardNodeStory
        }
      }
    }
  }
`;

const StoryTemplate = ({ data }) => (
  <LayoutContainer doc={{ ...data?.doc }}>
    <PageHeadMeta
      title={data?.doc.title}
      description={toPlainText(data?.doc._rawExcerpt)}
      image={data?.doc.mainImage}
      doc={data?.doc}
    />
    {data?.doc && (
      <CardContext.Provider value={{ ...data?.doc }}>
        <StoryPage
          {...data?.doc}
          suggested={mapEdgesToNodes(data?.recentStories)}
        />
      </CardContext.Provider>
    )}
  </LayoutContainer>
);

export default StoryTemplate;
